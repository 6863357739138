<template>
	<v-dialog :value="true" min-width="300" max-width="400" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title class="modal-title">Re-Assign Task</v-card-title>

			<v-card-text>
				<v-alert v-if="modalErrorMessage" color="error" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<employee-picker 
					v-model="assigned_to_user_id" 
					:disabled="saving"
					label="Assigned To User *"
					class="mt-3"
					placeholder="Who receives this task"
					dense outlined hide-details>
				</employee-picker>

			</v-card-text>

			<v-card-actions>
				<v-btn :disabled="saving" @click="modal.trigger('close')" class="ml-auto">Close</v-btn>
				<v-btn color="primary" @click="submit" :loading="saving">
					Assign
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { IdType } from '../../utils/IdType';
	import EmployeePicker from '../fields/employeePicker';

	export default {
		name: "reAssignTask",

		components: { EmployeePicker },

		props: {
			modal: {
				type: Object,
				required: true,
			},
			taskId: {
				type: IdType,
				required: true,
			},
		},

		data() {
			return {
				modalErrorMessage: false,
				saving: false,
				assigned_to_user_id: null,
			};
		},

		apollo: {
			task: {
				query: gql`
					query GetSomeTaskDetails($taskId: ID!) {
						 task(id: $taskId) {
							id
							assigned_to_user_id
						}
					}
				`,
				variables() {
					return {
						taskId: this.taskId
					}
				},
				skip() {
					return !this.taskId;
				},
				update(serverData) {
					this.assigned_to_user_id = serverData.task.assigned_to_user_id
					return serverData.task;
				},
			}
		},

		methods: {
			submit() {
				if (this.task.assigned_to_user_id === this.assigned_to_user_id) {
					this.modalErrorMessage = "Please pick a different user";
					return;
				}

				this.saving = true;

				this.$apollo
					.mutate({
						mutation: gql`
							mutation ($data: UpdateTaskArgs!) {
								updateTask(data: $data) {
									id
									assigned_to_user_id
								}
							}
						`,
						variables: {
							data: {
								id: this.taskId,
								assigned_to_user_id: this.assigned_to_user_id
							}
						},
						update:  (store, { data: { updateTask } }) => {
							this.saving = false;
							this.$snotify.success("Task reassigned");
							this.modal.trigger("save close", updateTask);
							this.$db.addModels("task", updateTask);
						},
					})
					.catch((error) => {
						console.error('error reassigning task', error);
						this.saving = false;
						this.modalErrorMessage = "There was a problem reassigning the task.";
					})

			},
		},
	};
</script>